<template>
  <div class="wrapper">
    <scroll-view @load-more="loadData" ref="scrollView">
      <img
        :src="item.url"
        class="pdf-img"
        alt=""
        v-for="(item, index) in list"
        :key="index"
      />
    </scroll-view>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref } from "vue";
import scrollView from "@/components/scroll-view";
import { useRouter } from "vue-router";
import { loadpdf } from "../../pages/index/loadPdf.js";
export default defineComponent({
  name: "pre-view-pdf",
  props: {},
  components: {
    scrollView,
  },
  setup() {
    const router = useRouter();
    const scrollView = ref(null);
    let query = router.currentRoute.value.query;
    let data = reactive({
      list: [],
      isEnd: false,
    });
    const loadData = (index) => {
      if (data.isEnd) return;
      loadpdf(index, query).then((res) => {
        scrollView.value.loadSuccess(res.end);
        data.list = data.list.concat(res.list);
        data.isEnd = res.end;
      });
    };
    return {
      loadData,
      scrollView,
      ...toRefs(data),
    };
  },
});
</script>

<style lang="scss" scoped>
.pdf-img {
  width: 100%;
}
</style>
