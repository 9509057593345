import { socket } from '@/config'
import webSocket from './websocket'
let time = null
export function connectSocket(currentUid,params){
    if(currentUid == null){
        return
    }
    let socketUrl = Object.keys(params).reduce((parent,current) => {
        return parent + `${current}=${params[current]}&`
    },`${socket}/imserverV2/${currentUid}/1?`)
    socketUrl = socketUrl.substring(0,socketUrl.length - 1)
    console.log(socketUrl);
    const ws = new webSocket();
    ws.initSocket(socketUrl)
    ws.on('onopen',()=>{
        time = setInterval(()=> ws.ws.send("心跳检测"),50000)
    })
    ws.on('onerror',()=>{
        clearInterval(time)
    })
}

