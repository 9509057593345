<template>
  <video
    :poster="`${
      seedingId.split('?')[0]
    }?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,f_png,ar_auto`"
    :src="seedingId"
    :style="{ height: height + 'px' }"
    id="ad-video"
    autoplay="autoplay"
    controls
  ></video>
</template>

<script>
import { defineComponent, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "",
  setup() {
    const router = useRouter();
    const query = reactive(router.currentRoute.value.query);
    const height = ref(window.screen.availHeight);
    return {
      ...toRefs(query),
      height,
    };
  },
});
</script>
<style lang="scss">
#ad-video {
  width: 94%;
  margin: 0 3%;
  object-fit: scale-down;
  background: #000;
}
</style>
