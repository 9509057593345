export function getStorage(key) {
  return window.localStorage.getItem(key)
}

export function setStorage(key,value) {
  return window.localStorage.setItem(key,value)
}

export function removeStorage(key) {
  return window.localStorage.removeItem(key)
}
