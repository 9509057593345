<template>
    <!-- 轮播图 -->
    <van-swipe class="swiper" :indicator-dots="true">
        <van-swipe-item v-for="(t, i) in bannerImage" :key="i">
            <div class="swiperView"><img @click.stop="reviewImg(t)" :src="t"/></div>
        </van-swipe-item>
    </van-swipe>

    <!-- 商品介绍 -->
    <div class="details">
        <div class="de_one">
            <div class="price">
                <text class="price_text">￥{{ goodsInfo.price }}</text>
            </div>
            <div class="browseNum">销量 : {{ goodsInfo.salesVolume }}件</div>
        </div>

        <!-- 描述 -->
        <div class="description">{{ goodsInfo.pname }}</div>
        <!-- 气泡 -->
        <div class="bubble">
            <text v-for="(t, i) in bubble" :key="i">{{ t }}</text>
        </div>
    </div>
    <div class="lines"></div>
    <!-- 商铺信息 -->
    <div class="shop">
        <div class="shop_top">
            <!-- 商铺头像 -->
            <div class="logo"><img :src="storeInfo.logo" mode=""/></div>
            <!-- 商铺文字 -->
            <div class="">
                <!-- 商铺名称 -->
                <div class="name">{{ storeInfo.name }}</div>
                <!-- 数量 -->
                <div class="num">
                    <text>{{ storeInfo.allNum }}</text>
                    <text>{{ storeInfo.monthNum }}</text>
                    <text>{{ storeInfo.browseNum }}</text>
                </div>
                <!-- 浏览量 -->
                <div class="text">
                    <text>累计浏览量</text>
                    <text>月销量</text>
                    <text>浏览量</text>
                </div>
            </div>
        </div>
    </div>
    <div class="lines"></div>

    <!-- 商品详情图 -->
    <div class="deta">
        <div class="deta_text">商品详情</div>
        <div class="deta_img"><img src="@/assets/f77a.png"/></div>
        <div class="descriptions">{{ goodsInfo.description }}</div>
        <div class="detailsimgs" v-for="(t, i) in detailsimg" @click.stop="reviewImg(t)" :key="i"><img :src="t" style="width: 100%;"/></div>
    </div>

    <!-- 底部 -->
    <div class="bottom">
        <div class="wxopen" v-html="wxOpenTags"></div>
        <div class="footer">
            <div class="price pc">
                <text class="price_text">￥{{ goodsInfo.price }}</text><text class="bt_sellMode">{{ goodsInfo.sellMode }}件起批</text>
            </div>
            
            <div @click="buygoods" class="storeright">
                立即购买
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import loadData from './loadData'
import { reviewImg } from '@/utils/wxsdk.js'

export default defineComponent({
    setup(){
        const router = useRouter(); 
        const query = router.currentRoute.value.query
        const data = loadData(query)
        console.log(data,'------------')
        return {
            ...toRefs(data),
            reviewImg
        }
    },
});
</script>

<style lang="scss" scoped>
.pc{
    padding-left: 30px
}
.lines{
    height: 10px;
    background-color: #f7f7f7;
}
.bt_sellMode{
    padding-left: 20px
}
.descriptions {
    padding: 5px 10px 10px 0;
    /* width: 650rpx; */
    /* height: auto; */
    margin: 0 auto;
    font-size: 28rpx;
    color: #333333;
}
.bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
}
.storeright {
    margin-top: -7px;
    width: 80px;
    height: 34px;
    background: #1676fe;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 34px;
    border-radius: 3px;
    margin-right: 30px;
}
.wxopen{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
}

.footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 25px;
    background-color: #ffffff;
    padding: 15px 0;
}
.footer .kefu {
    margin-left: -20px;
}
.footer img {
    margin-top: -4px;
    width: 24px;
    height: 24px;
}
.deta {
    width: 100%;
    background-color: #ffffff;
    margin-top: 10px;
    border-radius: 8px;
    margin-bottom: 35px;
}
.deta_text {
    margin-left: 15px;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin-bottom: -7px;
}
.deta_img img {
    margin-left: 15px;
    width: 60px;
    height: 10px;
}
.detailsimgs {
    width: 100%;
    height: auto;
}

.text,
.num {
    display: flex;
}
.text text,
.num text {
    text-align: center;
    width: 75px;
    height: 20px;
    font-size: 10px;
    color: #999999;
}
.num text {
    color: #1676fe;
    font-size: 12px;
    font-weight: 600;
}
.logo img {
    width: 55px;
    height: 55px;
    border-radius: 8px;
    margin-right: 15px;
}
.shop_top {
    display: flex;
    width: 100%;
    height: 65px;
}
.bubble {
    height: auto;
    margin: 0 auto;
    font-size: 14px;
    margin-top: 10px;
}
.bubble text {
    background-color: #f5f5f5;
    color: #999999;
    padding: 0 5px;
    margin-right: 15px;
    border-radius: 7px;
}
.description {
    overflow-y: auto;
    /* height: auto; */
    margin: 0 auto;
    /* padding-right: 30rpx; */
    font-size: 16px;
    color: #333333;
    margin-top: 10px;
}
.de_one {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}
.browseNum {
    font-size: 14px;
    color: #666666;
}
/* 价钱 */
.price {
    font-weight: bold;
    color: #bc2b37;
    font-size: 14px;
}

.details,
.shop {
    height: auto;
    background-color: #ffffff;
    border-radius: 0 0 8px 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
}
.shop {
    padding-top: 10px;
    border-radius: 8px;
}

.swiper {
    margin-top: -17px;
    width: 100%;
    height: 250px;
}
.swiper img {
    width: 100%;
    height: 100%;
}
.swiperView {
    height: 300rpx;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.header{
  height: auto;
  margin: 0 auto;
}
</style>