
import { getPdfFile } from "@/request";

let isOldApi = true,currentNum = 0,firstInfo = {}, maxNum = null;
export function loadpdf(index,params) {
    let size = 10,list = [];
    if (!isOldApi) {
      handleData(list,currentNum,handleNum(currentNum += 10,maxNum),firstInfo.url)
      return Promise.resolve({
        list,
        end: currentNum >= maxNum
      });
    }
    return getPdfFile({
      caseId: params.id,
      index,
      size,
    })
      .then((res) => {
        const urlList = res.urlList.list;
        firstInfo = urlList[0];
        isOldApi = /http/g.test(firstInfo.url);
        if (!isOldApi) {
            maxNum = firstInfo.pageNum;
            handleData(list,currentNum,handleNum(currentNum += 10,maxNum),firstInfo.url)
            return {
                list,
                end: currentNum >= maxNum
            }
        }
        list = list.concat(urlList);
        console.log(list);
        return {
            list,
            end: res.urlList.isLastPage
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

function handleData(list,startNum,endNum,path){
    let i = startNum;
    while (i < endNum) {
        i++;
        list.push({
            url: `https://xnddapp.oss-cn-beijing.aliyuncs.com/${path}/${i}.jpg`,
        });
    }
}
function handleNum(num,maxNum){
    if(num > maxNum){
        num = maxNum
    }
    return num;
}