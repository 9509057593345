import axios from 'axios'
import { baseURL } from '@/config.js'
import { getStorage,removeStorage } from '../utils/auth.js'
// import { wxAuthorize } from "../utils/utils";
const instance = axios.create({
    baseURL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
});
instance.interceptors.request.use(function (config) {
    const info = JSON.parse(getStorage('info'))
    if( info ){
        config.headers.token = info.token
    }
    return config;
});
instance.interceptors.response.use(function (response) {
    const statuCode = [501,502,503,504,505]
    if(response.status === 200 && response.data.code === 1){
        return response.data.data;
    }else if(statuCode.includes(response.data.code)){
        // removeStorage("info")
        // window.location.href = wxAuthorize(window.location.href)
    }else if(response.data.code === 506){
        removeStorage("info")
        window.location.reload()
    } else {
        return Promise.reject(response.data)
    }
});

export default instance