<template>
  <div class="wrapper" style="width: 100%">
      <div class="content">
		<div class="infobox">
			<img :src="userInfo.headimage" class="logo"/>
			<div class="name">{{userInfo.username}} 邀请您加入</div>
		</div>
		<van-form @submit="onSubmit">
        <van-field
            v-model="params.name"
            name="name"
            label="姓名"
            placeholder="姓名（必填）"
            :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <van-field
            v-model="params.phone"
            name="phone"
            label="手机号"
            placeholder="手机号（必填）"
            :rules="[{ pattern: phonePattern, message: '请填写正确手机号' }]"
        />
        <van-field
            v-model="params.code"
            name="code"
            label="验证码"
            placeholder="验证码（必填）"
            :rules="[{ required: true, message: '请填写验证码' }]"
            >
            <template #button>
                <van-button size="small" @click="sendCode" type="primary">{{btnText}}</van-button>
            </template>
        </van-field>
        <van-field
            v-model="params.password"
            type="password"
            name="password"
            label="密码"
            placeholder="密码（必填）"
            :rules="[{ required: true, message: '请填写密码' }]"
        />
        <div style="margin: 16px;">
            <van-button block type="primary" native-type="submit">提交</van-button>
        </div>
        </van-form>
	</div>

  </div>
</template>

<script>
    import { defineComponent,reactive,toRefs } from 'vue'
    import { useRouter } from 'vue-router'
    import { getInfobyId,registerManager,getCode } from '@/request'
    import { Toast } from 'vant';
    export default defineComponent({
        name:'',
        setup(){
            const phonePattern = /^1[3-9][0-9]\d{8}/
            const router = useRouter(); 
            const query = router.currentRoute.value.query
            const data = reactive({ userInfo: {},params: {},btnText: '发送验证码' })
            getInfobyId({
                // 传给后台的参数
                companyId: query.companyId,
                uid: query.uid
            })
            .then(res => {
                data.userInfo = res.data
                document.title = data.userInfo.company
            })
            .catch(err => {
                // 打印报错信息
                console.log('request fail', err);
            });
            const onSubmit = (values) => {
                console.log('submit',  data.userInfo);
                registerManager({
                    ...values,
                    departmentid: data.userInfo.departmentId,
                    companyid: data.userInfo.companyId,
                }).then(res=>{
                    console.log(res)
                    Toast('注册成功！');
                    setTimeout(() => {
                        router.push(`../register-success/register-success?name=${data.userInfo.company}`)
                    }, 1000);
                }).catch(err=>{
                    console.log(err)
                    Toast(err.msg);
                })
            }
            const sendCode = () => {
                console.log(data.params.phone)
                if(data.btnText != '发送验证码')return;
                if(phonePattern.test(data.params.phone)){
                    getCode({
                        phone: data.params.phone
                    }).then(res=>{
                        Toast('发送成功！');
                        let num = 60
                        data.btnText = `${--num}秒后重试`
                        let timer = setInterval(() => {
                            if(num <= 0){
                                clearInterval(timer)
                                data.btnText = '发送验证码'
                            } else {
                                data.btnText = `${num--}秒后重试`
                            }
                        }, 1000);
                        console.log(res)
                    }).catch(err=>{
                        Toast(err.msg);
                    })
                }else{
                    Toast('请填写合法手机号！');
                }
            }
            return {
                ...toRefs(data),
                onSubmit,
                sendCode,
                phonePattern
            }
        },
    });
</script>

<style lang="scss" scoped>

.infobox {
	height: 50px;
	display: flex;
    padding-left: 15px;
	align-items: center;
	font-size: 16px;
}
.infobox img {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	margin-right: 15px;
}

.box {
	width: 325px;
	margin: 10px 15px;
	padding: 10px;
	background-color: #ffffff;
	box-shadow: 0px 0px 3px #888888;
}



</style>