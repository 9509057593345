<template>
  <suspense>
    <template #default>
      <router-view />
    </template>
    <template #fallback>
      <van-loading size="24" />
    </template>
  </suspense>
</template>

<script>
// import { record } from 'rrweb'
// let events = [];
export default {
  name: "App",
  setup() {
    // startRecord()
    // // 开始录制
    // function startRecord() {
    //   // 这里是连接两个页面存在本地的，如果只有一个页面可以忽略这里的判断
    //   if(!window.sessionStorage.getItem('events') || window.sessionStorage.getItem('events')=='') {
    //       events = []
    //   } else {
    //       events = JSON.parse(window.sessionStorage.getItem('events'));
    //   }
    //   window.sessionStorage.removeItem('events');
    //   // 开始录制
    //   record({
    //       emit(event) {
    //           events.push(event);
    //       },
    //       sampling: {
    //           // 定义不录制的鼠标交互事件类型，可以细粒度的开启或关闭对应交互录制
    //           mouseInteraction: true,
    //           mousemove: true,
    //           scroll: 150, // 每 150ms 最多触发一次
    //           // 设置输入事件的录制时机
    //           input: 'last' // 连续输入时，只录制最终值
    //       },
    //   });
    // }
    // setInterval(saveMyrecord, 10000);
    // // 保存功能(在提交订单的时候触发)
    // function saveMyrecord() {
    //     if(!events || events.length == 0){return;}
    //     const body = JSON.stringify(events);
    //     console.log(body);
    // }
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  color: #999999;
}
input::-moz-input-placeholder {
  color: #999999;
}
input::-ms-input-placeholder {
  color: #999999;
}
html,
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
form,
input,
textarea,
th,
td,
select {
  margin: 0;
  padding: 0;
}
</style>
