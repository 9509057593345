<template>
  <video
    id="videoId"
    @timeupdate="timeupdate"
    :poster="`${
      dataInfo.video?.split('?')[0]
    }?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,f_png,ar_auto`"
    :src="dataInfo.video"
    class="video-content"
    autoplay="autoplay"
    controls="controls"
  ></video>
  <!-- 视频浮窗广告 -->
  <div class="float-win" @click.stop="btnClick" v-show="isShowAd">
    <div class="float-body">
      <div class="float-container"></div>
      <div class="float-fiexd-container">
        <div class="float-text">
          <img :src="adInfo.logo" class="ad-image" alt="" />
          <div style="margin-left: 10px" class="float-title">
            <div class="modelName" style="font-size: 13px">
              {{ adInfo.modelName }}
            </div>
            <div class="float-label">
              {{ adInfo.title }}
            </div>
          </div>
        </div>
        <div class="float-btn">查看详情</div>
      </div>
    </div>
  </div>
  <div class="icon-body">
    <div class="items">
      <img src="@/assets/view.png" class="icon-items" alt="" />
      <p class="item-text">{{ dataInfo.seeNum }}</p>
    </div>
    <div class="items">
      <img src="@/assets/farword.png" class="icon-items" alt="" />
      <p class="item-text">{{ dataInfo.shareNum }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "dd-video",
  emits: ["btnClick", "paused"],
  props: {
    dataInfo: {
      type: Object,
      default: () => {},
    },
    adInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const btnClick = () => {
      context.emit("btnClick");
    };
    // 要求两秒后显示广告
    let isShowAd = ref(false);
    setTimeout(() => {
      props.adInfo.logo && (isShowAd.value = true);
    }, 2000);

    const timeupdate = () => {
      const video = document.querySelector("#videoId");
      context.emit("paused", video.paused);
    };
    return {
      isShowAd,
      btnClick,
      timeupdate,
    };
  },
});
</script>

<style lang="scss" scoped>
.icon-body {
  position: fixed;
  bottom: 208px;
  right: 20px;
}
.icon-items {
  width: 41px;
  height: 41px;
}
.item-text {
  color: #ffffff;
  margin-top: 4px;
  text-align: center;
}
.video-content {
  width: 100%;
  object-fit: scale-down;
  background: #000;
  height: 100%;
  position: absolute;
  top: 0;
}
.float-win {
  position: fixed;
  bottom: 108px;
  width: 100%;
  color: #ffffff;
}
.float-body {
  position: relative;
}
.float-text {
  display: flex;
}
.float-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.float-container {
  width: 236px;
  height: 57px;
  background: #000000;
  opacity: 0.5;
  border-radius: 4px;
  margin-left: 20px;
}
.modelName {
  width: 96px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.float-label {
  color: #c4c4c4;
  width: 96px;
  font-size: 11px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.float-fiexd-container {
  width: 236px;
  position: absolute;
  left: 20px;
  display: flex;
  top: 0;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.ad-image {
  width: 37px;
  height: 37px;
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
}
.float-btn {
  width: 68px;
  height: 30px;
  background: #3478f6;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
}
</style>
