const KEYS = [ 'onopen', 'onclose', 'onerror', 'onmessage' ]
export default class webSocket{
    constructor(options = { isHeartCheck: false, timeout: 10000 }){
        // websocket实例
        this.ws = null;
        // 事件池
        this._eventList = new Map()
        // 是否连接
        this._isConnect = false;
        // 心跳检测
        this.heartCheck = new heartCheck()
        // 心跳检测频率
        this.timeout = options.timeout;
        // 当前重连次数
        this._connectNum = 0;
        // 心跳检测开关
        this.isHeartCheck = options.isHeartCheck;
    }
    on(eventName,callback){
        if(typeof callback !== 'function'){
            throw('callback Need to be a function')
        }
        let eventFn = this._eventList.get(eventName) 
        eventFn ? eventFn.push(callback) : (eventFn = [callback])
        this._eventList.set(eventName,eventFn)
        return this;
    }
    emit(eventName,...params){
        if(this._eventList.has(eventName)){
            this._eventList.get(eventName).forEach(callback=>callback(...params))
        }
        return this;
    }
    off(eventName){
        this._eventList.delete(eventName)
        return this;
    }
    initSocket(options){
        this.emit('beforeConnect',this)
        Promise.resolve().then(()=>{
            this.ws = new WebSocket(options);
            for(let key in this.ws){
                if(KEYS.includes(key)){
                    this._eventFunction(key)
                }
            }
        })
    }
    _eventFunction(key){
        this.ws[key] = (ws) => {
            if(key === 'onopen'){
                this._isConnect = true;
                this._heartbeatCheck()
            }
            this.emit(key,this.ws,ws)
        }
    }
    _heartbeatCheck(){
        if(!this._isConnect || !this.isHeartCheck)return;
        this.heartCheck.start(this)
    }
}
class heartCheck{
    constructor(timeout = 10000){
        this.timeout = timeout
        this.timeoutObj = null
    }
    reset() {
        clearTimeout(this.timeoutObj);
        return this;
    }
    start(websocket) {
        websocket.on('onmessage',(ws)=>{
            console.log(ws);
        })
        this.timeoutObj && setInterval(this.timeoutObj);
        this.timeoutObj = setInterval( () =>{
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            websocket.ws.send("HeartBeat");
        }, this.timeout)
    }
}