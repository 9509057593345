import { Dialog } from 'vant';
import { Toast } from 'vant';
import { reviewImg } from '@/utils/wxsdk.js' //,shareWxTimeline,shareWxFriend
import { addShareForm,pushShareMessage } from '@/request'
const submitFrom = (target,shareRecordId,query,articleId)=>{
    const inputsCon = target.parentNode.parentNode.querySelectorAll('.input-item')
    let data = [];
    let formId = target.parentNode.parentNode.getAttribute('data-mce-id')
    for(let el of Array.from(inputsCon)){
        const inputLabel = el.querySelector('.input-label')
        const input = el.querySelector('input')
        const verify = input.getAttribute('data-mce-is-verify')
        if(verify && !(new RegExp(verify).test(input.value))){
            data = [];
            return Dialog({ title: '提示',message: `${inputLabel.innerText}不合法！`,closeOnClickOverlay: true });
        }
        data.push({key: inputLabel.innerText,value: input.value})
    }
    const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '提交中，请稍等',
    });
    addShareForm({
        shareRecordId,
        formId,
        articleId,
        formText: JSON.stringify(data)
    }).then(()=>{
        data = [];
        Toast('提交成功！');
        toast.clear();
        for(let el of Array.from(inputsCon)){
            const input = el.querySelector('input')
            input.value = ''
        }
        pushShareMessage({
            shareId: query.uid,
            type: 3
        })
    }).catch(err=>{
        console.log(err)
        toast.clear();
        Toast('提交失败！');
    })
}
// 文章点击
export const handlerArticle = (event,shareRecordId,query,articleId) => {
    const target = event.target
    // 提交表单
    if(target.className == "btn-input"){
        submitFrom(target,shareRecordId,query,articleId)
    }else{
        reviewImg(event)
    }
}

