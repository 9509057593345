<template>
  <div class="wrapper" style="padding: 20px" v-html="agreement.content"></div>
</template>

<script>
import { defineComponent, toRefs, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { getRegistrationAgreement,getSelPrivacy } from '@/request/index.js'
export default defineComponent({
  name:'',
  setup(){
    const router = useRouter(); 
    const query = router.currentRoute.value.query
    const ref = reactive({
        agreement: {}
    })
    if(query.type == 1){
        getRegistrationAgreement().then(res=>{
            ref.agreement = res.data || {}
        })
    }else{
        getSelPrivacy().then(res=>{
            ref.agreement = res.data || {}
        })
    }
    return {
        ...toRefs(ref),
    }
  },
});
</script>

<style lang="scss" scoped>

</style>