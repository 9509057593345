<template>
  <div class="wrapper">
    <img :src="item.url" @click="reviewImg" :key="index" v-for="(item,index) in pdfList" alt="">
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { reviewImg } from '@/utils/wxsdk.js' //,shareWxTimeline,shareWxFriend
export default defineComponent({
  name:'dd-pdf',
  props: {
      pdfDetails: {
          type: Object,
          default:()=>{}
      }
  },
  setup(props){
      const pdfList = computed(()=>{
          return props.pdfDetails?.list
      })
      return {
          pdfList,
          reviewImg
      }
  }
});
</script>

<style lang="scss">
 
</style>