<template>
  <meta name="referrer" content="never">
  <div class="active-title">{{data.info.title}}</div>
  <div class="caseDetails" v-html="htmlStr" @click="handlerArticle($event,query.shareRecordId,query,data.info.id)"></div>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { getArticle } from '@/request'
import { handlerArticle } from '../index/handlerArticle'

export default defineComponent({
    name:'shopDetails',
    setup(){
        const router = useRouter(); 
        const query = router.currentRoute.value.query
        const data = reactive({ info: {} })
        getArticle({
            companyId: query.companyId,
            articleId: query.seedingId,
            uid: query.uid //用户id
        }).then(res=>{
            data.info = res.data
            document.title = data.title || ''
        })
        const htmlStr = computed(()=>{
            if(!(data.info.contents && data.info.contents[0])){
                return ''
            }
            let val = data.info.contents[0].value
            return val.replace(/<video(([\s\S])*?)<\/video>/g,(match, p1) => {
                const src = /src="(([\s\S])*?)"/.exec(p1) && /src="(([\s\S])*?)"/.exec(p1)[1]
                return `
                    <video x5-video-player-fullscreen="true" x5-playsinline 
                        playsinline webkit-playsinlineautoplay="autoplay" controls="controls"
                        poster="${data.info.cover}" preload="auto" src="${src}"
                        ${p1}
                    </video>
                `
            })
        })
        
      return {
        htmlStr,
        data,
        handlerArticle,
        query
      }
    },
});
</script>

<style lang="scss" >
   .caseDetails{
        padding: 2px 16px;
		.rich_media_content * {
			max-width: 100%!important;
			box-sizing: border-box!important;
			-webkit-box-sizing: border-box!important;
			word-wrap: break-word!important;
		}
		.rich_media_content img {
			height: auto!important;
		}
        table {
            margin-bottom: 10px;
            border-collapse: collapse;
            display: table;
            width: 100%!important;
        }
		img {
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Chrome/Safari/Opera */
			-khtml-user-select: none; /* Konqueror */
			-moz-user-select: none; /* Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none; /* Non-prefixed version, currently not supported by any browser */
            // filter: brightness(0.8);
		}
		h1, h2, h3, h4, h5, h6 {
			font-weight: 400;
			font-size: 16px;
		}
		.rich_media_content {
			overflow: hidden;
			color: #333;
			font-size: 17px;
			word-wrap: break-word;
			-webkit-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
			text-align: justify;
			position: relative;
			z-index: 0;
		}
        video{
            width: 100%;
            max-height: 300px;
            object-fit: contain;
        }
        td, th {
            word-wrap: break-word!important;
            word-break: break-all!important;
            -webkit-hyphens: auto!important;
            -ms-hyphens: auto!important;
            hyphens: auto!important;
            padding: 5px 10px!important;
            border: 1px solid #DDD!important;
        }
	}
    img{
        width: 100%;
        height: 100%;
    }
    .active-title{
      padding-bottom: 10px;
      margin: 0px 40px;
      font-weight: bold;
      font-size: 23px;
      border-bottom: 1px solid #f6f6f6;
    }
</style>