<template>
  <remote-script
    src="https://js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.1.5.2.js"
  ></remote-script>
  <meta name="referrer" content="never" />
  <div style="background: #ffffff">
    <div class="title-body">
      <div
        class="title"
        style="user-select: text; -webkit-user-select: text; font-weight: bold"
      >
        {{ article.title }}
      </div>
      <div class="time">{{ article.createTime }}</div>
    </div>
    <div class="contents" @click="handlerArticle($event)" v-html="htmlStr"></div>
  </div>
</template>
<script>
import { defineComponent, toRefs, reactive } from "vue";
import { preViewArticle } from "@/request";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "preView",
  props: {},
  components: {},
  async setup() {
    document.title = "文章详情";
    const router = useRouter();
    const ref = reactive({
      htmlStr: "",
      article: {},
    });
    let query = router.currentRoute.value.query;
    const res = await preViewArticle({ id: query.id }, query.articlePath);
    console.log(res);
    if (res.data.delFlag == 1) {
      ref.htmlStr = "内容已被删除！";
      ref.article = {};
    } else {
      ref.htmlStr = res.data.contents[0].value;
      ref.article = res.data;
    }
    const handlerArticle = (event) => {
      if (event.target.tagName === "IMG" && window.uni) {
        window.uni.postMessage({
          data: {
            type: "showImage",
            params: event.target.src,
          },
        });
      }
    };
    return {
      ...toRefs(ref),
      handlerArticle,
    };
  },
});
</script>
<style>
::-webkit-scrollbar {
  width: 0;
  height: 1px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
</style>
<style lang="scss" scoped>
.title-body {
  padding: 2px 16px;
}
.title {
  color: rgba(80, 80, 80, 1);
  font-size: 18px;
  line-height: 200%;
}
.time {
  border-bottom: 1px solid #e7e7e7;
  color: rgba(166, 166, 166, 1);
  font-size: 12px;
  line-height: 150%;
  padding-bottom: 16px;
  margin-bottom: 22px;
  /* text-indent: 60rpx; */
}
html,
body,
#app {
  height: 100%;
}
.contents {
  background: #ffffff;
  padding: 2px 16px;
  .rich_media_content * {
    max-width: 100% !important;
    box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;
    word-wrap: break-word !important;
  }
  table {
    margin-bottom: 10px;
    border-collapse: collapse;
    display: table;
    width: 100% !important;
  }
  .rich_media_content img {
    height: auto !important;
  }
  img {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
    width: 100%;
    height: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
    font-size: 16px;
  }
  .rich_media_content {
    overflow: hidden;
    color: #333;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    text-align: justify;
    position: relative;
    z-index: 0;
    font-size: 17px;
  }
  td,
  th {
    word-wrap: break-word !important;
    word-break: break-all !important;
    -webkit-hyphens: auto !important;
    -ms-hyphens: auto !important;
    hyphens: auto !important;
    padding: 5px 10px !important;
    border: 1px solid #ddd !important;
  }
}
.godetails {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  z-index: 999;
  align-items: center;
  background: #ffffff;
  margin-top: -5px;
}
.godetails-title {
  font-weight: bold;
  flex: 1;
}
.godetails-btn {
  padding: 6px 12px;
  background: #f6f6f6;
  color: #000000;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.user-label {
  display: flex;
  height: 100%;
  padding-right: 20px;
}

.advideoTest {
  width: 100%;
  max-height: 300px;
  object-fit: scale-down;
  background: #000;
}
.user {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 15px 10px -15px #ccc;
  background: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0px;
  height: 65px;
  transition: transform 0.5s;
}
.wx {
  text-align: center;
  padding: 6px 9px 6px 0;
  display: flex;
  align-items: center;
}
.launch,
#launch-btn {
  width: 260px;
}
.wx img {
  width: 37px;
  height: 37px;
}
.joinWx {
  height: 15px;
  line-height: 15px;
  text-decoration: none;
  color: rgba(80, 80, 80, 1);
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

/*弹窗样式*/
.wxPopup {
  width: 313px;
  background-color: #fff;
}

.infos {
  display: flex;
  justify-content: space-between;
}

.myInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
}

.myPhoto img {
  border-radius: 19px;
  width: 35px;
  height: 35px;
}

.nameInfo {
  color: rgba(80, 80, 80, 1);
  font-size: 14px;
  line-height: 21px;
  margin: 27px 0 7px 0;
}

.joinInfo {
  color: rgba(80, 80, 80, 1);
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.close img {
  width: 16px;
  height: 16px;
  margin: 12px 13px 0 0;
}

.wxPhoto img {
  width: 165px;
  margin: 12px 75px 21px 82px;
}
.advert-video-box {
  padding: 20px;
  background-color: #f7f7f7;
  padding-bottom: 40px;
}
.advert-border {
  position: relative;
  overflow: hidden;
  border-radius: 7px;
}
// 图片广告
.advert-img-box {
  width: 94vw;
  margin: 20px auto 75px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  // margin-bottom: 40px;
  padding-bottom: 40px;
}
.adver-tag-bg {
  background: #000000;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  opacity: 0.3;
  padding: 3px 7px;
  font-size: 10px;
  border-radius: 7px;
}
.adver-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  color: #ffffff;
  font-size: 10px;
  padding: 3px 7px;
  border-radius: 7px;
}
.my-swipe {
  height: 176px;
  overflow: hidden;
}
.ad-img {
  background-repeat: no-repeat; /*背景图像不平铺*/
  background-position: center; /*图片居中显示*/
  background-size: cover; /*隐藏不需要显示的图片*/
  height: 100%;
  width: 100%;
}
.advideo {
  width: 100%;
}
</style>
