import { getCodeUrl } from '@/config.js'
let timeout
export function throttle(func, wait) {
    if(timeout)return;
    timeout = setTimeout(() => {
        func()
        timeout = null
    }, wait);
}

export let onceExecute = async (callback) => {
    if(callback){
        callback instanceof Promise ? await callback() : callback()
    }
    onceExecute = function(){ }
}

export function wxAuthorize(href) {
    let uri = encodeURIComponent(href); 
    // 跳转授权
    window.location.href = getCodeUrl(uri);
}