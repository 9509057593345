<template>
  <slot></slot>
  <div class="loading-body">
    <van-loading type="spinner" v-if="loadStatus === 'loading'" />
    <span v-else-if="loadStatus === 'loadmore'">～没有更多了～</span>
  </div>
</template>

<script>
import { defineComponent, onBeforeUnmount, ref } from "vue";
import { throttle } from "../../utils/utils";
export default defineComponent({
  name: "scroll-view",
  props: {},
  components: {},
  setup(props, ctx) {
    let loadStatus = ref("loading");
    let index = 1;
    ctx.emit("loadMore", index++);
    let oldPageHeight = 0;
    const scrollCallback = () => {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const newPageHeight = document.body.scrollHeight - document.body.clientHeight - 50;
      //   console.log(scrollTop, newPageHeight);
      if (scrollTop >= newPageHeight && oldPageHeight != newPageHeight) {
        oldPageHeight = newPageHeight;
        // 触底事件
        throttle(() => {
          ctx.emit("loadMore", index++);
        }, 500);
      }
    };
    window.addEventListener("scroll", scrollCallback);
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", scrollCallback);
    });
    const reset = () => {
      index = 1;
      ctx.emit("loadMore", index++);
    };
    const loadSuccess = (isMore) => {
      loadStatus.value = isMore ? "loadmore" : "loading";
    };

    return { reset, loadSuccess, loadStatus };
  },
});
</script>

<style lang="scss" scoped>
.loading-body {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
}
</style>
