<template>
  <video
    id="videoId"
    @timeupdate="timeupdate"
    :poster="`${
      videoSrc.split('?')[0]
    }?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,f_png,ar_auto`"
    :src="videoSrc"
    class="video-content"
    autoplay="autoplay"
    controls="controls"
  ></video>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "",
  props: {},
  setup() {
    const router = useRouter();
    let query = router.currentRoute.value.query;
    return {
      videoSrc: query.src,
    };
  },
});
</script>

<style lang="scss" scoped>
.video-content {
  width: 100%;
  object-fit: scale-down;
  background: #000;
  height: 100%;
  position: absolute;
  top: 0;
}
</style>
