import { getGoodsInfo, getShopInfo } from '@/request'
import { reactive } from 'vue'
import { originalId } from '@/config.js'

export default function loadData(query){
    const data = reactive({
        bannerImage: [],
        detailsimg: [],
        bubble: [],
        goodsInfo: {},
        storeInfo: {},
        wxOpenTags: ''
    })
    data.wxOpenTags = `<wx-open-launch-weapp
        username="${originalId}"
        path="pages/shopping/details?goodsId=${query.goodsId}&companyId=${query.companyId}&entry=index">
        <template>
            <style>.btn { padding: 12px;width: 100vw; }.btns { padding: 12px;width: 100vw; }</style>
            <button style="padding: 1000px;background-color: rgba(0, 0, 0, 0);"></button>
        </template>
    </wx-open-launch-weapp>`
    getGoodsInfo({
        id: query.goodsId,
        companyId: query.companyId
    }).then(res=>{
        const { goodsInfo } = res
        const { bannerImage = '', album = '',bubble = '' } = goodsInfo
        data.bannerImage = bannerImage.split(',');
        data.detailsimg = album.split(',');
        data.bubble = bubble.split(',');
        data.goodsInfo = goodsInfo;
        document.title = goodsInfo.pname
    }).catch(err=>{
        console.log(err)
    })
    getShopInfo({
        companyId: query.companyId
    }).then(res=>{
        data.storeInfo = res.shopInfo
    })
    return data
}