import { getArticle, getUserInfo, getAdvertising,addBrowsing,pushShareMessage,getPdfFile,setShareRecord } from '@/request'
import { reactive } from 'vue'
import { connectSocket } from '@/socket'
import ClipboardJS from 'clipboard'
// import axios from 'axios'
import { shareWxTimeline,shareWxFriend,pathUtils } from '@/utils/wxsdk.js'
import { getStorage } from '../../utils/auth'


export default function loadData(query,params){
    pushShareMessage({
        shareId: query.uid,
        type: 1,
        articleTitle: query.title,
        typePara: query.type
    })
    new ClipboardJS('.btn-know');
    console.log('query: ', query);
    setShareRecord({
        shareRecordId: query.shareRecordId,
        parentShareId: query.pid
    })
    let newsData = null,isOldApi = true;
    const data = reactive({
        info: {},
        userInfo: {},
        isLogin: !!getStorage("info"),
        adInfo: {},
        pdfDetails: {},
        isLoadTotal: false,
        pageIndex: 1,
        addBrows: function (params){
            return addBrowsing({
                shareId: query.uid,
                companyId: query.companyId,
                articleId: query.id,
                title: data.info.title || query.title,
                type: query.type,
                shareRecordId: query.shareRecordId,
                ...params
            })
        },
        getPdfFile: () => {
            const size = 10;
            if( data.isLoadTotal){
                return
            }
            loadpdf().then(res => {
                if(!data.pdfDetails.list){
                    data.pdfDetails = res.urlList
                    newsData = data.pdfDetails.list[0]
                    isOldApi = /http/g.test(newsData.url)
                    document.title = query.title
                    addBrows()
                    if (!isOldApi) {
                        data.pdfDetails.list = []
                        data.getPdfFile()
                        return
                    }
                }else{
                    data.pdfDetails.list = data.pdfDetails.list.concat(res.urlList.list) 
                }
                if(res.urlList.list.length < size){
                    data.isLoadTotal = true
                }
                data.pageIndex++
            })
            function loadpdf(){
                console.log(isOldApi)
                if( isOldApi ){
                    return getPdfFile({
                        caseId: query.id,
                        index: data.pageIndex, 
                        size
                    })
                }
                const pdfDetails = JSON.parse(JSON.stringify(data.pdfDetails))
                const pageNum = newsData.pageNum
                return new Promise((resolve)=>{
                    let num = 10;
                    if((pageNum < 10) || (size * data.pageIndex > pageNum)){
                        num = pageNum % 10
                    }
                    pdfDetails.list = []
                    let i = 0;
                    while(i < num){
                        i++
                        let index = (size * (data.pageIndex - 1)) + i
                        pdfDetails.list.push({
                            url: `https://xnddapp.oss-cn-beijing.aliyuncs.com/${newsData.url}/${index}.jpg`
                        })
                    }
                    resolve({ urlList: pdfDetails })
                })
            }
        }
    })
    
    const addBrows = () => {
        const socketParams = {
            shareId: query.uid,
            companyId: query.companyId,
            articleId: query.id,
            title: encodeURIComponent(data.info.title || query.title),
            type: query.type,
            shareRecordId: query.shareRecordId,
            authPhone: 0,
            phone: ''
        }
        connectSocket(params.currentUid,socketParams)
    }
    // pdf
    if(query.type == 4){
        // pdf
        data.getPdfFile()
    } else {
        getArticle({
            companyId: query.companyId,
            articleId: query.id, //案例id
            uid: query.uid //用户id
        }).then(async res=>{
            data.info = res.data
            if(data.info.contents[0]){
                let val = data.info.contents[0]?.value
                let list = getVideoSrc(val)
                console.log(list);
                const str = replaceVideo(val,list)
                data.info.contents[0].value = str
                // /**设置微信分享朋友圈监听*/   
                // /**设置微信分享好友监听*/
                const imageUrl = query.cover
                setTimeout(() => {
                    let orginPath = imageUrl.split('?')[0]
                    orginPath = /^https:\/\/xnddapp\.oss-cn-beijing\.aliyuncs\.com/.test(orginPath) && orginPath + '?x-oss-process=image/resize,m_fill,h_120,w_120' || orginPath
                    pathUtils((path,par)=>{
                        if(par.shareRecordId){
                            path += '?shareRecordId=' + par.shareRecordId
                        }
                        if(params.currentUid){
                            path += '&pid=' + params.currentUid
                        }
                        shareWxTimeline(query.title, path, orginPath)
                        shareWxFriend(query.title, getChineseAndPoint(str), path , orginPath)
                        console.log(path);
                    })
                }, 1000);
            }
            document.title = data.info.title
            addBrows()
        })
    }
    
    getUserInfo({
        uid: query.uid,
        companyId: query.companyId
    }).then(res=>{
        data.userInfo = res.card
    })
    // 获取广告
    query.adId && getAdvertising({
        id: query.adId,
    }).then(res=>{
        data.adInfo = res.data || {}
    })
    return data
}
function replaceVideo(val,list){
    let index = 0; 
    return val.replace(/<video(([\s\S])*?)<\/video>/g,(match, p1)=>{
        // const src = /src="(([\s\S])*?)"/.exec(p1)[1]
        const style = /style="(([\s\S])*?)"/.exec(p1) && /style="(([\s\S])*?)"/.exec(p1)[1]
        return `
            <video style="${style || 'width: 100%;max-height: 300px;margin: 20px 0 0;'}" x5-video-player-fullscreen="true" x5-playsinline 
                playsinline webkit-playsinlineautoplay="autoplay" controls ="controls"
                preload="auto" src="${list[index++]}"
                ${p1}
            </video>
        `
    })
}
function getVideoSrc(val){
    let list = []
    val.replace(/<video(([\s\S])*?)<\/video>/g,(match, p1)=>{
        const src = /src="(([\s\S])*?)"/.exec(p1)[1]
        list.push(src)
    })
    return list
}

 function getChineseAndPoint(strValue) {
    //匹配中文字符以及这些中文标点符号 。 ？ ！ ， 、 ； ： “ ” ‘ ' （ ） 《 》 〈 〉 【 】 『 』 「 」 ﹃ ﹄ 〔 〕 … — ～ ﹏ ￥
    var reg = /[\u4e00-\u9fa5|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g;
    var tempValue = strValue.match(reg); //匹配结果，可能为空，还需要进行判断
    var resultStr="";
    if(tempValue!=null){
        resultStr = tempValue.join("");
    }
    return resultStr; //返回匹配的字符串
}
