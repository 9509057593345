<template>
  <div @touchmove="touchmove">
    <meta name="referrer" content="never" />
    <template v-if="query?.isBusinessCard == 1 && Object.keys(data.userInfo).length > 0">
      <div style="height: 65px; width: 100%; background-color: #ffffff"></div>
      <div class="user" :style="{ transform: `translateY(${offset}px)` }">
        <div style="width: 260px" v-html="userStr"></div>
        <div class="user-label">
          <div class="wx">
            <a :href="'tel:' + data.userInfo.phone"
              ><img src="https://xnddapp.oss-cn-beijing.aliyuncs.com/bodadianhua.png"
            /></a>
          </div>
          <div class="wx" @click="open">
            <img src="https://xnddapp.oss-cn-beijing.aliyuncs.com/weixiniconssss.png" />
          </div>
        </div>
      </div>
    </template>

    <!-- 文章 -->
    <div
      class="contents"
      v-if="query.type == types.article"
      v-html="htmlStr"
      @click="handlerArticle($event, query.shareRecordId, query, query.id)"
    ></div>

    <!-- 视频 -->
    <dd-video
      @paused="paused"
      @btnClick="goPath"
      :data-info="data.info"
      :ad-info="data.adInfo"
      v-else-if="query.type == types.video"
    ></dd-video>

    <!-- pdf -->
    <dd-pdf :pdfDetails="data.pdfDetails" v-else-if="query.type == types.pdf"></dd-pdf>

    <template v-if="query.type != types.video">
      <!-- 新闻图片广告 -->
      <div class="advert-img-box" v-if="data.adInfo.type === 1">
        <div class="adver-tag-bg">广告</div>
        <div class="adver-tag">广告</div>
        <van-swipe
          class="my-swipe"
          :autoplay="3000"
          @click="goPath"
          indicator-color="white"
        >
          <van-swipe-item
            v-for="(item, index) in data.adInfo.urls"
            class="swiper-item"
            :key="index"
          >
            <div class="ad-img" :style="{ 'background-image': `url(${item})` }"></div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 新闻视频广告 -->
      <div class="advert-video-box" v-else-if="data.adInfo.type == 2">
        <div class="advert-border">
          <div class="adver-tag-bg">广告</div>
          <div class="adver-tag">广告</div>
          <video
            class="advideoTest"
            style=""
            x5-video-player-type="h5"
            preload="metadata"
            playsinline="true"
            webkit-playsinline="true"
            x-webkit-airplay="true"
            x5-video-orientation="portraint"
            x5-video-player-fullscreen="true"
            v-for="(item, index) in data.adInfo.urls"
            object-fit="contain"
            :src="item"
            :show-mute-btn="true"
            :poster="`${
              item.split('?')[0]
            }?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,f_png,ar_auto`"
            :muted="true"
            :autoplay="true"
            :loop="true"
            :controls="true"
            :key="index"
          ></video>
          <div class="godetails" @click.stop="goPath">
            <div class="godetails-title">{{ data.adInfo.modelName }}</div>
            <div class="godetails-btn">查看详情</div>
          </div>
        </div>
      </div>
    </template>

    <!-- 微信二维码 -->
    <van-popup round v-model:show="wxCode">
      <view class="wxPopup">
        <view class="infos">
          <view class="myInfo">
            <view class="myPhoto">
              <img :src="data.userInfo.image" />
            </view>
            <view class="txtInfo">
              <view class="nameInfo">{{ data.userInfo.name }}</view>
              <view class="joinInfo">添加我的微信</view>
            </view>
          </view>
        </view>
        <view class="wxPhoto">
          <img :src="data.userInfo.wx" />
        </view>
      </view>
    </van-popup>

    <van-popup
      :close-on-click-overlay="false"
      v-model:show="isShowCopy"
      round
      position="center"
    >
      <div class="btn-copy-body">
        <img src="../../assets/popup.png" class="btn-copy-img" alt="" />
        <div class="btn-know-text">好的内容记得要分享哦</div>
        <div class="btn-know-Symbol">~</div>
        <button
          class="btn-know"
          @click="clickCopy"
          :data-clipboard-text="data.userInfo.adUrl || '拷贝成功！'"
        >
          我知道了
        </button>
      </div>
    </van-popup>

    <view class="footer-box" v-if="!data.isLogin">
      <img src="../../assets/footer-icon.png" class="footer-icon" />
      <view class="footer-content">
        <view class="footer-btn" @click="handleLogin">
          <view>阅读全文</view>
          <img src="../../assets/icon-xiala.png" />
        </view>
        <view class="footer-tips">
          <view class="footer-line"></view>
          <view class="tips">授权解锁后展示更多内容</view>
          <view class="footer-line"></view>
        </view>
      </view>
    </view>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import loadData from "./loadData";
import { defineComponent, computed, ref } from "vue";
import { getAdvertiseSeeding, getWxShareParams } from "@/request";
import { handlerArticle } from "./handlerArticle";
import ddVideo from "@/components/dd-video.vue";
import ddPdf from "@/components/dd-pdf.vue";
import { H5URL } from "@/config.js";
import { wxLogin } from "@/request";
import { getStorage, setStorage } from "../../utils/auth.js";
import { wxAuthorize } from "../../utils/utils";
import { pathUtils } from "../../utils/wxsdk";

const types = {
  article: 1,
  video: 2,
  pdf: 4,
};

export default defineComponent({
  name: "index",
  components: {
    ddVideo,
    ddPdf,
  },
  async setup() {
    const router = useRouter();
    let query = router.currentRoute.value.query;
    if (query.code && !getStorage("info")) {
      try {
        const data = await wxLogin({
          code: query.code,
          JSurl: H5URL + "?code=" + query.code + "&state=123",
          uid: null,
        });
        setStorage("info", JSON.stringify(data));
      } catch (err) {
        console.log(err);
      }
    }

    let params = null;
    /**分享进入参数重写 从后台取*/
    if (query.shareRecordId) {
      params = await getWxShareParams({ id: query.shareRecordId });
      query = Object.assign(query, JSON.parse(params.data.wxQrCodePath), {
        currentUid: params?.data?.currentUid,
      });
    }

    // console.log(query)
    /**获取页面接口数据*/
    const data = loadData(query, params);
    let htmlStr = computed(() => {
      if (data.info?.contents) {
        return data.info?.contents[0]?.value;
      } else {
        return "";
      }
    });
    let wxCode = ref(false);
    const open = () => {
      data.addBrows({ operation: 3 });
      wxCode.value = true;
    };
    const telPhone = () => data.addBrows({ operation: 2 });
    const userStr = computed(() => {
      // <wx-open-launch-weapp id="launch-btn" style="width: 260px; username="${originalId}" path="pages/business/business?uid=${query.uid}&companyid=${query.companyId}"">
      //<template></template>
      // </wx-open-launch-weapp>
      return `
        <div class="info" style="width: 260px; display: flex;height: 65px">
            <div style="padding: 0px 0 0px 20px;height: 65px;display: flex;justify-content: center; align-items: center;">
                <img src="${
                  data.userInfo.image
                }" style="width: 39px;height: 39px;border-radius: 20px;margin-top: 2px;">
            </div>
            <div class="userInfo" style="padding: 6px 0 6px 0px;margin-left: 10px;display: flex;justify-content: center;flex-direction: column;">
                <div style="display: flex;align-items: center;">
                    <div style="font-weight: 700;font-size: 18px;color: #000000;">${
                      data.userInfo.name
                    }</div>
                    <div style="margin-left: 8px;width: 1px;height: 12px;background: #333333;"></div>
                    <div style="margin-left: 8px;font-size: 12px; color: #333333;">${
                      data.userInfo.jobLabel
                    }</div>
                </div>
                <div style="color: #999999;font-size: 12px;margin-top: 3px;">${
                  data.userInfo.companyShortName || ""
                }</div>
            </div>
        </div>
      `;
    });
    const goPath = () => {
      getAdvertiseSeeding({
        advertiseId: data.adInfo.id,
        uid: query.uid,
        companyId: query.companyId,
      }).then((res) => {
        const adInfo = res.data;
        const urlList = [
          "",
          `../videoDetails/videoDetails?seedingId=${adInfo.seedingUrl}`,
          `../shopDetails/shopDetails?companyId=${adInfo.companyId}&goodsId=${adInfo.seedingId}`,
          `../caseDetails/caseDetails?seedingId=${adInfo.seedingId}&company=${query.companyId}&uid=${query.uid}&shareRecordId=${query.shareRecordId}`,
          `../videoDetails/videoDetails?seedingId=${adInfo.seedingUrl}`,
        ];
        router.push(urlList[adInfo.linkType]);
      });
    };
    let oldTop = 0,
      offset = ref(0),
      maxTop = 0,
      minTop = -65,
      offsetValue = 0,
      oldPageHeight = 0;
    window.addEventListener("scroll", () => {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const offsetTop = scrollTop - oldTop;
      if (offsetTop > 0) {
        offsetValue > minTop
          ? (offsetValue -= offsetTop)
          : ((offsetValue = minTop), (offset.value = offsetValue));
      } else {
        offsetValue < maxTop
          ? (offsetValue += Math.abs(offsetTop))
          : ((offsetValue = maxTop), (offset.value = offsetValue));
      }
      oldTop = scrollTop;
      const newPageHeight = document.body.scrollHeight - document.body.clientHeight - 20;
      if (
        scrollTop >= newPageHeight &&
        oldPageHeight != newPageHeight &&
        query.type == types.pdf
      ) {
        oldPageHeight = newPageHeight;
        // 触底事件
        data.getPdfFile();
      }
    });
    let isShowCopy = ref(false);
    setTimeout(() => {
      if (data.userInfo.adUrl) {
        isShowCopy.value = true;
      }
    }, 1000);
    let clickCopy = () => (isShowCopy.value = false);
    const paused = (e) => {
      offset.value = e ? 0 : minTop;
    };
    const handleLogin = () => {
      pathUtils((path, par) => {
        if (par.shareRecordId) {
          path += "?shareRecordId=" + par.shareRecordId;
        }
        if (par.pid) {
          path += "&pid=" + par.pid;
        }
        wxAuthorize(path);
      });
    };

    const touchmove = (e) => !data.isLogin && e.preventDefault();
    return {
      touchmove,
      clickCopy,
      isShowCopy,
      data,
      htmlStr,
      query,
      types,
      userStr,
      open,
      wxCode,
      goPath,
      telPhone,
      offset,
      handlerArticle,
      paused,
      handleLogin,
    };
  },
  beforeUnmount() {
    window.removeEventListener("scroll", () => {});
  },
});
</script>

<style lang="scss" scope>
.popup-tips {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .tips-icon {
    position: absolute;
    bottom: 0;
    right: 20px;
    height: 67px;
    width: 284px;
  }
  .popup-content {
    width: 253px;
    height: 135px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .tips-title {
    font-size: 16px;
  }
  .tips-content {
    width: 140px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    text-align: center;
  }
}
.footer-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.footer-icon {
  width: 100%;
  height: 80px;
}
.footer-content {
  flex: 1;
  background: #ffffff;
}
.footer-btn {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3478f6;
  font-size: 14px;
  img {
    width: 12px;
    height: 12px;
    margin-left: 8px;
  }
}
.footer-tips {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  .tips {
    margin-right: 10px;
    margin-left: 10px;
  }
}
.footer-line {
  width: 92px;
  height: 1px;
  background: #97a8be;
}
.btn-copy-body {
  width: 180px;
  height: 228px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .btn-copy-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .btn-know-Symbol {
    position: relative;
    margin-top: 18px;
    color: #999999;
    font-size: 12px;
  }
  .btn-know {
    width: 115px;
    height: 30px;
    background: linear-gradient(315deg, #fa709a 0%, #fee140 100%);
    opacity: 1;
    border-radius: 16px;
    border: 0;
    color: #ffffff;
    position: relative;
    margin-top: 18px;
    font-size: 14px;
  }
  .btn-know-text {
    position: relative;
    margin-top: 110px;
    font-size: 12px;
    color: #333333;
  }
}

html,
body,
#app {
  height: 100%;
}
.contents {
  padding: 2px 16px;
  .rich_media_content * {
    max-width: 100% !important;
    box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;
    word-wrap: break-word !important;
  }
  table {
    margin-bottom: 10px;
    border-collapse: collapse;
    display: table;
    width: 100% !important;
  }
  .rich_media_content img {
    height: auto !important;
  }
  img {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
    width: 100%;
    height: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
    font-size: 16px;
  }
  .rich_media_content {
    overflow: hidden;
    color: #333;
    font-size: 17px;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    text-align: justify;
    position: relative;
    z-index: 0;
  }
  td,
  th {
    word-wrap: break-word !important;
    word-break: break-all !important;
    -webkit-hyphens: auto !important;
    -ms-hyphens: auto !important;
    hyphens: auto !important;
    padding: 5px 10px !important;
    border: 1px solid #ddd !important;
  }
}
.godetails {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  z-index: 999;
  align-items: center;
  background: #ffffff;
  margin-top: -5px;
}
.godetails-title {
  font-weight: bold;
  flex: 1;
}
.godetails-btn {
  padding: 6px 12px;
  background: #f6f6f6;
  color: #000000;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.user-label {
  display: flex;
  height: 100%;
  padding-right: 20px;
}
.txtInfo {
  margin-left: 10px;
}
.advideoTest {
  width: 100%;
  max-height: 300px;
  object-fit: scale-down;
  background: #000;
}
.user {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 15px 10px -15px #ccc;
  background: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0px;
  height: 65px;
  transition: transform 0.5s;
}
.wx {
  text-align: center;
  padding: 6px 9px 6px 0;
  display: flex;
  align-items: center;
}
.launch,
#launch-btn {
  width: 260px;
}
.wx img {
  width: 37px;
  height: 37px;
}
.joinWx {
  height: 15px;
  line-height: 15px;
  text-decoration: none;
  color: rgba(80, 80, 80, 1);
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

/*弹窗样式*/
.wxPopup {
  width: 313px;
  background-color: #fff;
}

.infos {
  display: flex;
  justify-content: space-between;
}

.myInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
}

.myPhoto img {
  border-radius: 19px;
  width: 35px;
  height: 35px;
}

.nameInfo {
  color: rgba(80, 80, 80, 1);
  font-size: 14px;
  line-height: 21px;
  margin: 27px 0 7px 0;
}

.joinInfo {
  color: rgba(80, 80, 80, 1);
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.close img {
  width: 16px;
  height: 16px;
  margin: 12px 13px 0 0;
}

.wxPhoto img {
  width: 165px;
  margin: 12px 75px 21px 82px;
}
.advert-video-box {
  padding: 20px;
  background-color: #f7f7f7;
  padding-bottom: 40px;
}
.advert-border {
  position: relative;
  overflow: hidden;
  border-radius: 7px;
}
// 图片广告
.advert-img-box {
  width: 94vw;
  margin: 20px auto 75px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  // margin-bottom: 40px;
  padding-bottom: 40px;
}
.adver-tag-bg {
  background: #000000;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  opacity: 0.3;
  padding: 3px 7px;
  font-size: 10px;
  border-radius: 7px;
}
.adver-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  color: #ffffff;
  font-size: 10px;
  padding: 3px 7px;
  border-radius: 7px;
}
.my-swipe {
  height: 176px;
  overflow: hidden;
}
.ad-img {
  background-repeat: no-repeat; /*背景图像不平铺*/
  background-position: center; /*图片居中显示*/
  background-size: cover; /*隐藏不需要显示的图片*/
  height: 100%;
  width: 100%;
}
.advideo {
  width: 100%;
}
</style>
