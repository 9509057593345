<template>
  <div class="content">
    <div class="box">
      <img src="@/assets/success.jpeg" mode="" />
      <div class="success">加入公司成功！</div>
      <div class="detail">
        您已申请加入&nbsp;&nbsp;【{{ name }}】
        <div class="">请等待企业管理员审核</div>
      </div>
      <a id="J_weixin" class="android-btn" :href="url">下载小牛叮当APP</a>
      <div id="weixin-tip">
        <p>
          <img src="@/assets/down.webp" mode="" />
          <span id="close" title="关闭" class="close">×</span>
        </p>
      </div>
      <div class="tip">审核结果将显示在小牛叮当APP内，请前往下载</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from 'vue-router'
export default defineComponent({
  name: "",
  props: {},
  components: {},
  setup() {
    const router = useRouter(); 
    const query = router.currentRoute.value.query
    const data = reactive({ url: "",name: query.name });
    var u = navigator.userAgent
    const isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    data.url = "https://node.9ndada.com/xiaoniudingdang.apk";
    if (isIos) {
      // 相关逻辑代码
      data.url = "https://apps.apple.com/cn/app/%E5%B0%8F%E7%89%9B%E5%8F%AE%E5%BD%93/id1531678183";
    }
    return {
      ...toRefs(data),
    };
  },
});
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
}
.box img {
  margin-top: 150px;
  width: 100px;
  height: 100px;
}
.success {
  font-size: 18px;
  height: 77px;
  line-height: 77px;
}
.detail {
  font-size: 12px;
  height: 45px;
  line-height: 23px;
  text-align: center;
  margin-bottom: 45px;
}
.tip {
  font-size: 12px;
  margin-bottom: 15px;
}

a {
  text-decoration: none;
}
a:link {
  color: #fff;
}
a:visited {
  color: #fff;
}
a:hover {
  color: #fff;
}
a:active {
  color: #fff;
}
#J_weixin {
  background-color: #0084bf;
  color: #ffffff;
  font-size: 19px;
  margin-bottom: 25px;
  height: 45px;
  text-align: center;
  line-height: 45px;
}
#weixin-tip {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  filter: alpha(opacity=80);
  width: 100%;
  height: 100vh;
  z-index: 100;
}
#weixin-tip p {
  text-align: center;
  margin-top: 10%;
  width: 100%;
  position: relative;
}
#weixin-tip image {
  width: 100%;
  height: 275px;
}
#weixin-tip .close {
  color: #fff;
  padding: 5px;
  font: bold 20px/24px simsun;
  text-shadow: 0 1px 0 #ddd;
  position: absolute;
  top: 0;
  left: 5%;
}
</style>
